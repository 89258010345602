body{
  background-color: #f5f5f5;
  font-family: 'Roboto Slab', serif;
  color:black
}

.navbar{
  position:fixed;
  z-index: 100;
  width:100%;
}

.nav-list{
  display:flex;
  justify-content: flex-end;
  align-items: center;
  background-color: black;
  box-shadow: 0px 0px 10px #cfd8dc;
  margin: 0; 
  padding: 5px 0;
}

.nav-item{
  list-style: none;
  margin-right: 2rem;
}

.nav-item:hover a{
  color:#AD8350;
  transition: 0.5s ease-in-out;
}

.navLinks::after {
  content: "";
  width: 0%;
  height: 3px;
  background: #AD8350;
  display: block;
  margin: auto;
  transition: 0.5s;
}

.navLinks:hover::after {
  width: 100%;
}

.nav-item a {
  text-decoration: none;
  color:white
}

.nav-item:first-child{
  margin-right:auto;
  margin-left: 2rem;
}

.aboutContainer{
  display:flex;
  height:100%;
  width:100%
}

#aboutImg{
  background-image: url('../public/images/laptop-home-office.webp');
  background-size: auto 100%;
  flex:1
}

#abouttext{
  flex:1;
  display:flex;
  justify-content: center;
  flex-direction: column;
}

#abouttext h1{
  color:#AD8350;
  margin-left: 100px;
  margin-right: 100px;
  text-align: center;
}

#abouttext h1::after {
  content: "";
  width:95px;
  height: 3px;
  background: #AD8350;
  display: block;
  margin:auto
}

#abouttext>p{
  margin-left: 100px;
  margin-right: 100px;
}

#abouttext ul{
  list-style: none;
  margin-right:20px;
  margin-left:20px;
  text-align: center;
}

#abouttext ul li{
  display:inline-block;
  margin-right:15px;
}

#abouttext ul li a{
  color:white
}

#abouttext ul li a:hover{
  color:#AD8350;
  transition: 0.5s ease-in-out;
}

#abouttext ul p{
  margin-top:0px;
  margin-bottom: 0px;
}

.myLists{
  padding-inline-start: 0px;
}

.slide{
  display:flex;
  justify-content: center;
  align-items: center;
}

.slide img{
  height: 650px
}

.links{
  display:flex;
  justify-content: space-around;
  gap:50px
}

.links a:hover{
  background-color: #AD8350;
  transition: 0.5s ease-in-out;
}

.links a{
  text-decoration: none;
  font-size: 30px;
  padding:5px;
  border: 1px solid white;
  color:white
}

.introContainer{
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color:white
}

#introSection{
  background: url('/public/images/computer\ background.jpg');
  background-size: cover;
  background-position: center;
}

.introContainer h1{
  font-size: 60px;
  margin-top: 0;
  margin-bottom: 0;
}

.introContainer h2{
  font-size: 45px;
}

.contactContainer{
  display:flex;
  justify-content: center;
  align-items: center;
}

#aboutSection{
  background-color: #0f1922;
  color:white
}

#contactSection{
  background-color: #0f1922;
  color:white
}

#meContainer img{
  height:350px;
  border-radius: 50%;
}

#pokemonContainer{
  background:linear-gradient(to bottom, rgba(0,0,0,0) 20%, rgba(0,0,0,.75)), url('/public/images/pokemon game.png');
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  display:flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  color: white;
  padding-bottom: 100px;
}

#dajaviewContainer{
  background:linear-gradient(to bottom, rgba(0,0,0,0) 20%, rgba(0,0,0,.75)), url('/public/images/daja view.png');
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  display:flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  color: white;
  padding-bottom: 100px;
  text-shadow: -1px -1px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000
}

#syntacticallysugarContainer{
  background:linear-gradient(to bottom, rgba(0,0,0,0) 20%, rgba(0,0,0,.75)), url('/public/images/syntactically sugar.png');
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  display:flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  color: white;
  padding-bottom: 100px;
}

#logo{
  height:30px
}

.contactContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactContainer h1{
  color:#AD8350;
}


.contactContainer h1::after {
  content: "";
  width:100%;
  height: 3px;
  background: #AD8350;
  display: block;
}

.contactContainer ul{
  list-style: none;
  display:flex;
  flex-direction: column;
  margin:0;
  padding:0
}

.contactContainer ul li{
  display:inline-block;
  margin-bottom:15px
}

.contactContainer ul li a{
  color:white
}

.contactContainer ul li a:hover{
  color:#AD8350;
  transition: 0.5s ease-in-out;
}

#slideContainer ul{
  list-style: none;
  margin-right:20px;
  margin-left:20px;
  text-align: center;
  margin-bottom: 20px;
  padding:0
}

#slideContainer ul li{
  display:inline-block;
  margin-right:15px;
}

@media(max-width: 800px){
  #aboutImg{
    display:none;
    width:0%
  }
}

@media(max-width: 500px){
  .nav-item:first-child{
    display:none
  }
  #meContainer img{
    height:250px;
    margin-top:40%
  }

  .introContainer h1{
    font-size: 40px;
  }

  .introContainer h2{
    font-size: 30px;
  }
  .nav-item{
    font-size: 15px
  }
  #slideContainer > div.fp-arrow.fp-controlArrow.fp-next{
    border-width: 20px 0 20px 20px;
    top:33%
  }
  #slideContainer > div.fp-arrow.fp-controlArrow.fp-prev{
    border-width: 20px 20px 20px 0;
    top:33%
  }
  #pokemonContainer > p{
    margin-left:15px;
    margin-right: 15px;
    padding-bottom: 0px;
  }

  #syntacticallysugarContainer > p{
    margin-left:15px;
    margin-right: 15px;
    padding-bottom: 0px;
  }

  #dajaviewContainer > p{
    margin-left:15px;
    margin-right: 15px;
    padding-bottom: 0px;
  }

  .contactContainer{
    margin-top:50%
  }
}